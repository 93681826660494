import React, { useContext, useEffect, useMemo, useState } from "react";
import { OffersContext } from "../context/Offers";
import { Button, Form } from "react-bootstrap";

const NamingContainer = () => {
  const { naming, getNaming, createNaming, deleteNaming } =
    useContext(OffersContext);

  const [name, setName] = useState("");

  const [propNames, setPropNames] = useState([""]);
  const [propValues, setPropValues] = useState([""]);

  const submitNaming = () => {
    const entries = [];

    for (let i = 0; i < propNames.length; i++) {
      entries.push([propNames[i], propValues[i]]);
    }
    const props = Object.fromEntries(entries);

    createNaming({
      name,
      props: JSON.stringify(props),
    });

    setName("");
    setPropNames([""]);
    setPropValues([""]);
  };

  const isValid = useMemo(
    () =>
      Boolean(name) &&
      propNames.every((item) => Boolean(item)) &&
      propValues.every((item) => Boolean(item)),
    [name, propNames, propValues]
  );

  const updatePropName = (index, value) => {
    setPropNames((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        result.push(i === index ? value : prev[i]);
      }

      return result;
    });
  };

  const updatePropValue = (index, value) => {
    setPropValues((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        result.push(i === index ? value : prev[i]);
      }

      return result;
    });
  };

  const createPair = () => {
    setPropNames((prev) => [...prev, ""]);
    setPropValues((prev) => [...prev, ""]);
  };

  useEffect(() => {
    getNaming();
  }, []);

  const resetForm = () => {
    setName("");
    setPropNames([""]);
    setPropValues([""]);
  };

  return (
    <div
      style={{
        width: "40%",
        padding: "10px",
        borderRadius: "8px",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        position: "relative",
        maxWidth: "345px",
        maxHeight: "535px",
        minHeight: "435px",
      }}
    >
      <h5
        style={{
          marginBottom: "10px",
          padding: "5px",
        }}
      >
        Naming
      </h5>
      <div
        style={{
          width: "100%",
          height: "40%",
          maxHeight: "300px",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          padding: "5px",
        }}
      >
        {naming.map(({ id, name, props }, index) => {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontStyle: "bold",
                  }}
                >
                  {index + 1}. {name}
                </p>

                <p
                  style={{
                    fontStyle: "bold",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    deleteNaming(id);
                  }}
                >
                  delete
                </p>
              </div>

              {Object.entries(JSON.parse(props)).map(([key, value]) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid grey",
                    gap: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "blue",
                    }}
                  >
                    {key}
                  </p>
                  <p>{value}</p>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          height: "50%",
          padding: "5px",
          maxHeight: "350px",
          overflowY: "scroll",
        }}
      >
        <Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Form.Label>Name</Form.Label>

            <p
              style={{
                fontStyle: "bold",
                color: "orange",
                cursor: "pointer",
              }}
              onClick={resetForm}
            >
              Reset form
            </p>
          </div>

          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          style={{
            marginTop: "10px",
          }}
        >
          <Form.Label>Props</Form.Label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {propNames.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Form.Control
                  value={item}
                  onChange={(e) => {
                    updatePropName(index, e.target.value);
                  }}
                />

                <Form.Control
                  value={propValues[index]}
                  onChange={(e) => {
                    updatePropValue(index, e.target.value);
                  }}
                />
              </div>
            ))}
          </div>
          <Button
            style={{
              marginTop: "10px",
            }}
            onClick={createPair}
          >
            +
          </Button>
        </Form.Group>
      </div>
      <Button
        variant="success"
        disabled={!isValid}
        style={{
          marginTop: "20px",
          position: "absolute",
          bottom: 15,
          right: 15,
        }}
        onClick={submitNaming}
      >
        Add
      </Button>
    </div>
  );
};

export default NamingContainer;
