import styled from "styled-components";

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  * {
    box-sizing: border-box;
  }
`;

export const LocationItem = styled.div`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  width: 295px;

  border: 1px solid transparent;
  &:hover {
    background-color: #e3fdd8;
    border: 1px dashed green;
  }

  ${({ createBtn }) =>
    createBtn &&
    `background-color: #e0edff; border: 1px dashed blue; width: 130px`};
`;
