import React from "react";

const ShareIcon = () => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5 9.5L13.5148 0.5V5.5C1.58699 5.5 0.210903 15.178 0.542202 20.5C1.04314 17.815 1.27565 13.5 13.5148 13.5V18.5L20.5 9.5Z"
          stroke="#000057"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ShareIcon;
