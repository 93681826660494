import axios from "axios";

export const API_URL =
  process.env.REACT_APP_API_URL ?? "https://offers.axioma.marketing";

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization =
    window.localStorage.getItem("admin_token") ?? "";

  return config;
});

export default instance;
