import React, { useContext } from "react";
import OffersContainer from "../Containers/Offers";
import { OffersContext } from "../context/Offers";
import { Modal } from "react-bootstrap";
import Offer from "../Components/Offer";

const Offers = () => {
  const {
    offersModalOpen,
    closeOffersModal,
    addNewOffer,
    currentOffer,
    initialOffer,
    editOffer,
    deleteOffer,
    defaults,
  } = useContext(OffersContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "20px",
        }}
      >
        <OffersContainer />
      </div>

      <Modal show={offersModalOpen} onHide={closeOffersModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {currentOffer ? "Edit offer ID: " + currentOffer.id : "New offer"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Offer
            onAddNewOffer={addNewOffer}
            editOffer={editOffer}
            currentOffer={currentOffer}
            initialOffer={initialOffer}
            deleteOffer={deleteOffer}
            defaults={defaults}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Offers;
