import React from "react";
import Check from "../assets/check-icon.png";
import like from "../assets/like.png";
import ShareIcon from "../assets/ShareIcon";

const OfferCard = ({ offer, likesCount }) => {
  if (!offer) return null;

  return (
    <div
      className={
        offer?.ticker
          ? "card__wrapper card__wrapper__bezVidigrashu"
          : "card__wrapper"
      }
    >
      {offer?.ticker && (
        <div
          className="b-marquee b-marquee--rtl"
          style={{ background: `${offer?.tickerBackground}` }}
        >
          <div className="b-marquee__text">
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
            <span style={{ margin: "0 10px" }}>{offer.ticker}</span>
          </div>
        </div>
      )}

      <div className="card__offer">
        <div
          className={`card__top`}
          style={{
            background: `url("${offer?.headerImageUrl}") no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            {offer?.tags?.split(",")?.map((tag) => (
              <div
                key={offer?.name + tag}
                className="offer_tag"
                style={{
                  background: offer?.tagsBackground,
                }}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
        <div className="card__middle">
          <div className="card__img__title__container">
            <img className="card__img" src={offer?.avatarImageUrl} />
            <p className="card__title">{offer?.title}</p>
            <img className="card__check__icon" src={Check} alt="check-icon" />
          </div>
          <div className="card__text__container">
            <p className="card__text__bonus">{offer?.description}</p>
            {offer?.trigger && (
              <p className="card__text__bezvidigrashu">{offer.trigger}</p>
            )}
            <p className="card__text__terms">{offer.terms}</p>
          </div>
          {offer?.promo ? (
            <div className="card__offer__promo">
              <div className="card__offer__text__container">
                <p className="card__offer__text">{offer?.promo}</p>
              </div>
              <div className="card__offer__btn__container">
                <button className="card__offer__btn">{"копіювати"}</button>
              </div>
            </div>
          ) : (
            <div className="card__offer__nopromo">
              <p className="card__offer__nopromo__text">Промокод не потрібен</p>
            </div>
          )}

          <div className="card__btn__container ">
            <button className="card__btn deep-link">вперед на сайт</button>
          </div>
        </div>
        <div className="card__bottom">
          <div className="card__like__container">
            <div className="card__like__img">
              <img className="card__like__img" src={like} alt="" />
            </div>
            <p className="card__like__text">{likesCount ?? 0} Подобається</p>
          </div>
          <div className="card__bottom__border" />
          <div className="card__share__container">
            <div className="card__share__img">
              <ShareIcon />
            </div>
            <p className="card__share__text">Поділитись</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
