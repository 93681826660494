import React, { useContext, useEffect, useMemo, useState } from "react";
import { OffersContext } from "../context/Offers";
import truncate from "../utils/truncate";
import { LocationsContainer, LocationItem } from "./DefaultsStyles";

const DefaultsContainer = () => {
  const { defaults, getAllDefaults, onCreateNewDefaultClick, getOneDefault } =
    useContext(OffersContext);

  useEffect(() => {
    getAllDefaults();
  }, []);

  return (
    <section>
      <LocationsContainer>
        <LocationItem
          key="add=offer"
          onClick={onCreateNewDefaultClick}
          createBtn
        >
          <div>
            <strong
              style={{
                marginRight: "15px",
              }}
            >
              +
            </strong>
            New location
          </div>
        </LocationItem>

        {defaults?.map((item) => (
          <LocationItem
            key={`${item.id}${item.code}loc`}
            onClick={() => {
              getOneDefault(item.id);
            }}
          >
            <div>
              <strong
                style={{
                  marginRight: "15px",
                }}
              >
                {item.id}
              </strong>
              {truncate(item?.code)}
            </div>
          </LocationItem>
        ))}
      </LocationsContainer>
    </section>
  );
};

export default DefaultsContainer;
