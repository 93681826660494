import React, { useEffect, useState, useContext, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { OffersContext } from "../context/Offers";

const DefaultsItem = () => {
  const { currentDefaults, editDefaults, createDefault } =
    useContext(OffersContext);

  const [showOffers, setShowOffers] = useState("");
  const [code, setCode] = useState("");
  const [showAll, setShowAll] = useState("");
  const [showPromo, setShowPromo] = useState("");
  const [pid, setPid] = useState("");
  const [sub1, setSub1] = useState("");
  const [substitution, setSubstitution] = useState("");

  useEffect(() => {
    setShowOffers(currentDefaults?.showOffers);
    setCode(currentDefaults?.code);
    setPid(currentDefaults?.pid);
    setSub1(currentDefaults?.sub1);
    setShowAll(currentDefaults?.showAll);
    setShowPromo(currentDefaults?.showPromo);
    setSubstitution(currentDefaults?.substitution);
  }, [currentDefaults]);

  const onInputChange = (e, setState) => setState(e.target.value);

  const isEdited = useMemo(() => {
    const states = {
      showOffers,
      pid,
      sub1,
      showAll,
      showPromo,
      substitution,
      code,
    };

    for (let key in currentDefaults) {
      if (currentDefaults[key] !== states[key]) {
        return true;
      }
    }

    return false;
  }, [
    showOffers,
    pid,
    sub1,
    currentDefaults,
    showAll,
    showPromo,
    substitution,
    code,
  ]);

  return (
    <div
      style={{
        backgroundColor: "#85FFBD",
        backgroundImage: "linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)",
        padding: "15px 15px 60px",
        borderRadius: "8px",
        maxWidth: "280px",
        maxHeight: "535px",
        position: "relative",
        margin: "0 auto",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      }}
    >
      <Form.Group>
        <Form.Label>Country code</Form.Label>
        <Form.Control
          value={code}
          onChange={(e) => onInputChange(e, setCode)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Show offers on main page (ids)</Form.Label>
        <Form.Control
          value={showOffers}
          onChange={(e) => onInputChange(e, setShowOffers)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Show promo</Form.Label>
        <Form.Control
          value={showPromo}
          onChange={(e) => onInputChange(e, setShowPromo)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Show offers on all casinos page (ids)</Form.Label>
        <Form.Control
          value={showAll}
          onChange={(e) => onInputChange(e, setShowAll)}
        />
      </Form.Group>
      <Form.Group
        style={{
          marginTop: "10px",
        }}
      >
        <Form.Label>Default pid</Form.Label>
        <Form.Control value={pid} onChange={(e) => onInputChange(e, setPid)} />
      </Form.Group>
      <Form.Group
        style={{
          marginTop: "10px",
        }}
      >
        <Form.Label>Default Sub1</Form.Label>
        <Form.Control
          value={sub1}
          onChange={(e) => onInputChange(e, setSub1)}
        />
      </Form.Group>
      <Button
        variant="success"
        style={{
          marginTop: "20px",
          position: "absolute",
          bottom: 15,
          right: 15,
        }}
        disabled={!isEdited && currentDefaults}
        onClick={() => {
          if (currentDefaults) {
            editDefaults({
              code,
              showOffers,
              showPromo,
              showAll,
              sub1,
              pid,
              substitution,
            });
          } else {
            createDefault({
              code,
              showOffers,
              showPromo,
              showAll,
              sub1,
              pid,
              substitution,
            });
          }
        }}
      >
        {currentDefaults ? "Save" : "Create"}
      </Button>
    </div>
  );
};

export default DefaultsItem;
