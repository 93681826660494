import axios, {API_URL} from "./axios";

export default {
  async getOne(id) {
    const { data } = await axios.get(`/offers/${id}`);

    return data;
  },

  async deleteOne(id) {
    const { data } = await axios.delete(`/offers/${id}`);

    return data;
  },

  async getAll(getDark = true) {
    const url = "/offers/all" + (getDark ? "/4488" : "");

    const { data } = await axios.get(url);

    return data;
  },

  async create(newOfferData) {
    const { data } = await axios.post("/offers/new", newOfferData);

    return data;
  },

  async edit(id, dataToUpdate) {
    const { data } = await axios.patch(`/offers/${id}`, dataToUpdate);

    return data;
  },

  async uploadImag(fromDataImage) {
    const options = {
      method: "POST",
      body: fromDataImage,
      headers: {
        Authorization: window.localStorage.getItem("admin_token") ?? "",
      },
    };

    const data = fetch(
      `${API_URL}/offers/upload`,
      options
    );

    return (await data).json();
  },

  async getAllDefaults() {
    const { data } = await axios.get(`/offers/defaults-all`);

    return data;
  },

  async getDefaults(id = "") {
    const { data } = await axios.get(`/offers/defaults/${id}`);

    return data;
  },

  async getNamings() {
    const { data } = await axios.get(`/offers/namings`);

    return data;
  },

  async createNaming(newNamingData) {
    const { data } = await axios.post("/offers/namings/create", newNamingData);

    return data;
  },

  async deleteNaming(id) {
    const { data } = await axios.delete(`/offers/namings/${id}`);

    return data;
  },

  async editDefaults(dataToUpdate, id = "") {
    const { data } = await axios.patch(`/offers/defaults/${id}`, dataToUpdate);

    return data;
  },

  async createDefault(dataToCreate) {
    const { data } = await axios.post("/offers/defaults/create", dataToCreate);

    return data;
  },

  async getStatistics() {
    const { data } = await axios.get(`/offers/statistics`);

    return data;
  },

  async getDb() {
    const response = await axios.get(`/offers/getdb`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "db.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
