import React, { createContext, useState } from "react";
import api from "../api";

export const OffersContext = createContext({});

const OffersContextProvider = ({ children }) => {
  const [allOffers, setAllOffers] = useState([]);
  const [currentOffer, setCurrenOffer] = useState(null);
  const [initialOffer, setInitialOffer] = useState(null);
  const [defaults, setDefaults] = useState([]);
  const [currentDefaults, setCurrentDefaults] = useState(null);
  const [naming, setNaming] = useState([]);
  const [offersModalOpen, setOffersModalOpen] = useState(false);
  const [defaultsModalOpen, setDefaultsModalOpen] = useState(false);

  const [showDark, setShowDark] = useState(true);

  const openOffersModal = () => setOffersModalOpen(true);
  const closeOffersModal = () => {
    setCurrenOffer(null);
    setOffersModalOpen(false);
  };

  const openDefaultsModal = () => setDefaultsModalOpen(true);
  const closeDefaultsModal = () => {
    setCurrentDefaults(null);
    setDefaultsModalOpen(false);
  };

  const getAllDefaults = async () => {
    const defs = await api.offers.getAllDefaults();

    setDefaults(defs);
  };

  const getOneDefault = async (id) => {
    const def = await api.offers.getDefaults(id);
    setCurrentDefaults(def);

    openDefaultsModal();
  };

  const createDefault = async (data) => {
    await api.offers.createDefault(data);
    await getAllDefaults();
    setCurrentDefaults(null);
    closeDefaultsModal();
  };

  const getNaming = async () => {
    const namings = await api.offers.getNamings();

    setNaming(namings);
  };

  const createNaming = async (namingData) => {
    await api.offers.createNaming(namingData);
    await getNaming();
  };

  const deleteNaming = async (id) => {
    await api.offers.deleteNaming(id);
    await getNaming();
  };

  const getAllOffers = async (getDark) => {
    const offers = await api.offers.getAll(getDark ?? showDark);

    setAllOffers(offers);
  };

  const getOneOffer = async (id) => {
    const offer = await api.offers.getOne(id);
    setCurrenOffer(offer);
    openOffersModal();
  };

  const addNewOffer = async (newOfferData) => {
    const data = await api.offers.create(newOfferData);
    setCurrenOffer(null);
    await getOneOffer(data.id);
    await getAllOffers();
  };

  const editDefaults = async (newData) => {
    await api.offers.editDefaults(newData, currentDefaults?.id);
    await getAllDefaults();
    closeDefaultsModal();
  };

  const editOffer = async (id, data) => {
    setCurrenOffer(null);
    await api.offers.edit(id, data);
    await getAllOffers();
    await getOneOffer(id);
  };

  const deleteOffer = async (offerId) => {
    setCurrenOffer(null);
    setOffersModalOpen(false);
    await api.offers.deleteOne(offerId);
    await getAllOffers();
  };

  const onCreateNewOfferClick = async () => {
    const id = prompt("Copy offer from id:", "");

    if (id && !isNaN(+id)) {
      const offer = await api.offers.getOne(+id);
      setInitialOffer(offer);
    } else {
      setInitialOffer(null);
    }

    setCurrenOffer(null);
    openOffersModal();
  };

  const onCreateNewDefaultClick = async () => {
    setCurrentDefaults(null);
    openDefaultsModal();
  };

  return (
    <OffersContext.Provider
      value={{
        onCreateNewDefaultClick,
        getOneDefault,
        createDefault,
        allOffers,
        offersModalOpen,
        showDark,
        setShowDark,
        currentDefaults,
        defaultsModalOpen,
        setDefaultsModalOpen,
        openOffersModal,
        closeOffersModal,
        getAllOffers,
        addNewOffer,
        deleteOffer,
        getOneOffer,
        currentOffer,
        initialOffer,
        editOffer,
        onCreateNewOfferClick,
        defaults,
        getAllDefaults,
        editDefaults,
        naming,
        getNaming,
        createNaming,
        deleteNaming,
        openDefaultsModal,
        closeDefaultsModal,
      }}
    >
      {children}
    </OffersContext.Provider>
  );
};

export default OffersContextProvider;
