import React, { useEffect, useState } from "react";
import NavBarContainer from "./Containers/NavBar";
import Auth from "./Components/Auth";
import Routes from "./pages/routes";

const ADMIN_PASSWORD = "Axioma!2022";

function Layout() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("admin_token");
    const password = window.localStorage.getItem("admin_password");

    setAuth(!!token && password === ADMIN_PASSWORD);
  }, []);

  const onLogin = ({ password, token }) => {
    if (!token || password !== ADMIN_PASSWORD) return;

    window.localStorage.setItem("admin_token", token);
    window.localStorage.setItem("admin_password", password);

    setAuth(true);
  };

  if (!auth) {
    return <Auth onLogin={onLogin} />;
  }

  return (
    <main
      style={{
        height: "100vh",
        background: "white",
      }}
    >
      <NavBarContainer />
      <Routes />
    </main>
  );
}

export default Layout;
