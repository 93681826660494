import React, { useEffect, useState } from "react";
import api from "../api";
import { Button } from "react-bootstrap";

const Statistics = () => {
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    api.offers.getStatistics().then((res) => setStatistics(res));
  }, []);

  return (
    <div
      style={{
        background:
          'url("https://images.unsplash.com/photo-1528818955841-a7f1425131b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c3RhcnJ5JTIwc2t5fGVufDB8fDB8fA%3D%3D&w=1000&q=80") center no-repeat',
        backgroundSize: "cover",
        margin: "20px auto",
        position: "relative",
        maxWidth: "500px",
        padding: "10px",
        borderRadius: "8px",
        boxShadow:
          "rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          color: "white",
        }}
      >
        <p>
          Всього аккаунтів -{" "}
          <strong>{statistics?.totalAccountsCount ?? "loading..."}</strong>
        </p>
        <p>
          З Google Id -{" "}
          <strong>{statistics?.totalGoogleAccounts ?? "loading..."}</strong>
          {Boolean(statistics) && (
            <strong
              style={{
                color: "hotpink",
                marginLeft: "20px",
              }}
            >
              {Math.floor(
                (statistics?.totalGoogleAccounts * 100) /
                  statistics?.totalAccountsCount
              )}
              %
            </strong>
          )}
        </p>
        <p>
          Підтверджених всього -{" "}
          <strong>{statistics?.verifiedAccountsCount ?? "loading..."}</strong>
          {Boolean(statistics) && (
            <strong
              style={{
                color: "hotpink",
                marginLeft: "20px",
              }}
            >
              {Math.floor(
                (statistics?.verifiedAccountsCount * 100) /
                  statistics?.totalAccountsCount
              )}
              %
            </strong>
          )}
        </p>
        <p>
          Підтверджених без гугла -{" "}
          <strong>
            {statistics?.verifiedAccountsCount -
              statistics?.totalGoogleAccounts || "loading..."}
          </strong>
          {Boolean(statistics) && (
            <>
              <strong
                style={{
                  color: "hotpink",
                  marginLeft: "20px",
                }}
              >
                {Math.floor(
                  ((statistics?.verifiedAccountsCount -
                    statistics?.totalGoogleAccounts) *
                    100) /
                    statistics?.totalAccountsCount
                )}
                %
              </strong>
              <strong
                style={{
                  color: "greenyellow",
                  marginLeft: "20px",
                }}
              >
                {Math.floor(
                  ((statistics?.verifiedAccountsCount -
                    statistics?.totalGoogleAccounts) *
                    100) /
                    (statistics?.totalAccountsCount -
                      statistics?.totalGoogleAccounts)
                )}
                % (mails only)
              </strong>
            </>
          )}
        </p>
        <p>
          Не підтверджених -{" "}
          <strong>{statistics?.notVerifiedCount ?? "loading..."}</strong>
          {Boolean(statistics) && (
            <>
              <strong
                style={{
                  color: "hotpink",
                  marginLeft: "20px",
                }}
              >
                {Math.floor(
                  (statistics?.notVerifiedCount * 100) /
                    statistics?.totalAccountsCount
                )}
                %
              </strong>
              <strong
                style={{
                  color: "greenyellow",
                  marginLeft: "20px",
                }}
              >
                {Math.floor(
                  (statistics?.notVerifiedCount * 100) /
                    (statistics?.totalAccountsCount -
                      statistics?.totalGoogleAccounts)
                )}
                % (mails only)
              </strong>
            </>
          )}
        </p>
        <p></p>
        <p>
          Нових сьогодні -{" "}
          <strong>{statistics?.newAccountsToday ?? "loading..."}</strong>
          {Boolean(statistics) && (
            <strong
              style={{
                color: "hotpink",
                marginLeft: "20px",
              }}
            >
              {Math.floor(
                (statistics?.newAccountsToday * 100) /
                  statistics?.totalAccountsCount
              )}
              %
            </strong>
          )}
        </p>
        <p>
          Нових вчора -{" "}
          <strong>{statistics?.newAccountsYesterday ?? "loading..."}</strong>
        </p>
        <p>
          Нових за тиждень -{" "}
          <strong>{statistics?.newAccountsThisWeek ?? "loading..."}</strong>
        </p>
        <p>
          Нових за місяць -{" "}
          <strong>{statistics?.newAccountsThisMonth ?? "loading..."}</strong>
        </p>
        <p></p>
        <p>
          Всього лайків -{" "}
          <strong>{statistics?.totalLikesCount ?? "loading..."}</strong>
        </p>
      </div>

      <Button
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
        }}
        onClick={() => {
          api.offers.getDb();
        }}
        variant="warning"
      >
        get db
      </Button>
    </div>
  );
};

export default Statistics;
