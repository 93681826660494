import React, { useContext, useEffect, useMemo, useState } from "react";
import { OffersContext } from "../context/Offers";
import truncate from "../utils/truncate";
import {
  FiltersContainer,
  OfferItem,
  OffersContainer,
  SearchInput,
} from "./OffersStyles";
import { Badge } from "react-bootstrap";

const Offers = () => {
  const {
    allOffers,
    getAllOffers,
    onCreateNewOfferClick,
    getOneOffer,
    getAllDefaults,
    defaults,
  } = useContext(OffersContext);

  useEffect(() => {
    getAllOffers();
    getAllDefaults();
  }, []);

  const [searchFilter, setSearchFilter] = useState("");
  const [locationsFilter, setLocationsFilter] = useState([]);

  const displayedOffers = useMemo(() => {
    return allOffers.filter(({ title, locations }) => {
      const searchMatches =
        title?.toLowerCase()?.substring(0, searchFilter.length) ===
        searchFilter.toLowerCase();

      const locationMatches =
        JSON.parse(locations || "[]").some((item) =>
          locationsFilter.includes(item)
        ) || !locationsFilter.length;

      return locationMatches && searchMatches;
    });
  }, [searchFilter, allOffers, locationsFilter]);

  return (
    <section
      style={{
        paddingLeft: "50px",
      }}
    >
      <FiltersContainer>
        <SearchInput
          type="text"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search by title"
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {defaults?.map(({ code, id }) => (
            <Badge
              key={code}
              bg={locationsFilter?.includes(id) ? "success" : "dark"}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setLocationsFilter((prev) =>
                  prev.includes(id)
                    ? prev.filter((item) => item !== id)
                    : [...prev, id]
                );
              }}
            >
              {code}
            </Badge>
          ))}
        </div>
      </FiltersContainer>

      <OfferItem key="add=offer" onClick={onCreateNewOfferClick} createBtn>
        <div>
          <strong
            style={{
              marginRight: "15px",
            }}
          >
            +
          </strong>
          New offer
        </div>
      </OfferItem>

      <OffersContainer>
        {displayedOffers?.map((item) => (
          <OfferItem
            key={`${item.id}${item.name}`}
            onClick={() => {
              getOneOffer(item.id);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <strong
                style={{
                  marginRight: "15px",
                }}
              >
                {item.id}
              </strong>
              {truncate(item?.title, 25)}
              <img
                src={item?.avatarImageUrl}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  marginLeft: "auto",
                }}
                alt=""
              />
            </div>
          </OfferItem>
        ))}
      </OffersContainer>
    </section>
  );
};

export default Offers;
