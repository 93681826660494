import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Badge } from "react-bootstrap";
import api from "../api";
import OfferCard from "./OfferCard";
import useIsEdited from "../hooks/useIsEdited";

const Offer = ({
  currentOffer,
  onAddNewOffer,
  editOffer,
  initialOffer,
  deleteOffer,
  defaults,
}) => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [isDark, setIsDark] = useState(true);
  const [description, setDescription] = useState("");
  const [avatarImageUrl, setAvatarImageUrl] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [link, setLink] = useState("");
  const [ticker, setTicker] = useState("");
  const [trigger, setTrigger] = useState("");
  const [tickerBackground, setTickerBackground] = useState("");
  const [tags, setTags] = useState("");
  const [tagsBackground, setTagsBackground] = useState("");
  const [promo, setPromo] = useState("");
  const [terms, setTerms] = useState("");
  const [viberLink, setViberLink] = useState("");
  const [locations, setLocations] = useState("");
  const [likes, setLikes] = useState("");

  useEffect(() => {
    setId(currentOffer?.id ?? "");
    setTitle(currentOffer?.title ?? "");
    setIsDark(currentOffer?.isGreat ?? true);
    setDescription(currentOffer?.description ?? "");
    setAvatarImageUrl(currentOffer?.avatarImageUrl ?? "");
    setHeaderImageUrl(currentOffer?.headerImageUrl ?? "");
    setLink(currentOffer?.link ?? "");
    setTicker(currentOffer?.ticker ?? "");
    setTrigger(currentOffer?.trigger ?? "");
    setTickerBackground(currentOffer?.tickerBackground ?? "");
    setTags(currentOffer?.tags ?? "");
    setTagsBackground(currentOffer?.tagsBackground ?? "");
    setPromo(currentOffer?.promo ?? "");
    setTerms(currentOffer?.terms ?? "");
    setViberLink(currentOffer?.viberLink ?? "");
    setLocations(currentOffer?.locations ?? "");
    setLikes(currentOffer?.likesCount ?? "");
  }, [currentOffer]);

  useEffect(() => {
    if (!initialOffer) return;

    setId(initialOffer?.id ?? "");
    setTitle(initialOffer?.title ?? "");
    setIsDark(initialOffer?.isGreat ?? true);
    setDescription(initialOffer?.description ?? "");
    setAvatarImageUrl(initialOffer?.avatarImageUrl ?? "");
    setHeaderImageUrl(initialOffer?.headerImageUrl ?? "");
    setLink(initialOffer?.link ?? "");
    setTicker(initialOffer?.ticker ?? "");
    setTrigger(initialOffer?.trigger ?? "");
    setTickerBackground(initialOffer?.tickerBackground ?? "");
    setTags(initialOffer?.tags ?? "");
    setTagsBackground(initialOffer?.tagsBackground ?? "");
    setPromo(initialOffer?.promo ?? "");
    setTerms(initialOffer?.terms ?? "");
    setViberLink(initialOffer?.viberLink ?? "");
    setLocations(initialOffer?.locations ?? "");
    setLikes(initialOffer?.likesCount ?? "");
  }, [initialOffer]);

  const isValid = useMemo(
    () =>
      Boolean(+id) &&
      Boolean(title) &&
      Boolean(description) &&
      Boolean(avatarImageUrl) &&
      Boolean(headerImageUrl) &&
      Boolean(viberLink) &&
      Boolean(link),
    [id, title, description, link, viberLink, headerImageUrl, avatarImageUrl]
  );

  const state = useMemo(
    () => ({
      id,
      title,
      isGreat: isDark,
      description,
      avatarImageUrl,
      headerImageUrl,
      link,
      ticker,
      trigger,
      tickerBackground,
      tags,
      tagsBackground,
      promo,
      viberLink,
      terms,
    }),
    [
      id,
      title,
      isDark,
      description,
      avatarImageUrl,
      headerImageUrl,
      link,
      ticker,
      trigger,
      tickerBackground,
      tags,
      tagsBackground,
      promo,
      viberLink,
      terms,
    ]
  );

  const isEdited = useIsEdited(currentOffer, state, ["likesCount", "id"]);

  const onInputChange = (e, setState) => setState(e.target.value);

  const [selectedAvatar, setSelectedAvatar] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState([]);

  const avatarPickHandler = (changeEvent) => {
    const selectedFile = changeEvent.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      setSelectedAvatar([event.target.result, changeEvent.target.files[0]]);
    };

    reader.readAsDataURL(selectedFile);
  };

  const headerPickHandler = (changeEvent) => {
    const selectedFile = changeEvent.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      setSelectedHeader([event.target.result, changeEvent.target.files[0]]);
    };

    reader.readAsDataURL(selectedFile);
  };

  const uploadAvatar = async () => {
    const formData = new FormData();

    formData.append("file", selectedAvatar[1]);

    const { url } = await api.offers.uploadImag(formData);

    setAvatarImageUrl(url);
    setSelectedAvatar([]);
  };

  const uploadHeader = async () => {
    const formData = new FormData();

    formData.append("file", selectedHeader[1]);

    const { url } = await api.offers.uploadImag(formData);

    setHeaderImageUrl(url);
    setSelectedHeader([]);
  };

  const appliedLocations = useMemo(() => {
    const allLocsArray = JSON.parse(locations || "[]");

    return defaults.filter(({ id }) => allLocsArray.includes(id));
  }, [locations]);

  return (
    <div
      className="flex flex-row justify-between items-center"
      style={{
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <div>
        <OfferCard
          offer={{
            id,
            title,
            description,
            avatarImageUrl,
            headerImageUrl,
            link,
            ticker,
            trigger,
            tags,
            tagsBackground,
            promo,
            viberLink,
            terms,
            tickerBackground,
          }}
          likesCount={currentOffer?.likesCount}
        />
      </div>
      <div
        style={{
          maxWidth: "400px",
          maxHeight: "77vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Form.Group className="flex flex-row items-center justify-between">
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Id
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={id}
            onChange={(e) => onInputChange(e, setId)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center"
        >
          <Form.Label
            style={{
              marginBottom: 0,
              marginRight: "35px",
            }}
          >
            Locations
          </Form.Label>
          <div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                marginBottom: "5px",
              }}
            >
              {appliedLocations?.map(({ code, id }) => (
                <Badge
                  key={code}
                  bg="success"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setLocations((prev) => {
                      const arr = JSON.parse(prev || "[]");
                      return JSON.stringify(arr.filter((item) => item !== id));
                    });
                  }}
                >
                  {code}
                </Badge>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              {defaults
                ?.filter(
                  ({ id }) => !appliedLocations.map(({ id }) => id).includes(id)
                )
                .map(({ code, id }) => (
                  <Badge
                    key={code}
                    bg="dark"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLocations((prev) => {
                        const arr = JSON.parse(prev || "[]");

                        arr.push(id);

                        return JSON.stringify(arr);
                      });
                    }}
                  >
                    + {code}
                  </Badge>
                ))}
            </div>
          </div>
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Title
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={title}
            onChange={(e) => onInputChange(e, setTitle)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Description
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={description}
            onChange={(e) => onInputChange(e, setDescription)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Trigger
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={trigger}
            onChange={(e) => onInputChange(e, setTrigger)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Promo
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={promo}
            onChange={(e) => onInputChange(e, setPromo)}
          />
        </Form.Group>

        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Tags
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={tags}
            onChange={(e) => onInputChange(e, setTags)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Tags background
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={tagsBackground}
            onChange={(e) => onInputChange(e, setTagsBackground)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Terms
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={terms}
            onChange={(e) => onInputChange(e, setTerms)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Avatar
          </Form.Label>
          <div>
            <Form.Control
              style={{
                maxWidth: "300px",
              }}
              value={avatarImageUrl}
              onChange={(e) => onInputChange(e, setAvatarImageUrl)}
            />
            <Form.Control
              style={{
                maxWidth: "300px",
              }}
              type="file"
              accept="image/*"
              onChange={avatarPickHandler}
            />
            <Button disabled={!selectedAvatar[0]} onClick={uploadAvatar}>
              upload
            </Button>
          </div>
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Header image
          </Form.Label>
          <div>
            <Form.Control
              style={{
                maxWidth: "300px",
              }}
              value={headerImageUrl}
              onChange={(e) => onInputChange(e, setHeaderImageUrl)}
            />
            <Form.Control
              style={{
                maxWidth: "300px",
              }}
              type="file"
              accept="image/*"
              onChange={headerPickHandler}
            />
            <Button disabled={!selectedHeader[0]} onClick={uploadHeader}>
              upload
            </Button>
          </div>
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Link
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={link}
            onChange={(e) => onInputChange(e, setLink)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Viber link
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={viberLink}
            onChange={(e) => onInputChange(e, setViberLink)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Ticker
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={ticker}
            onChange={(e) => onInputChange(e, setTicker)}
          />
        </Form.Group>
        <Form.Group
          style={{
            marginTop: "10px",
          }}
          className="flex flex-row items-center justify-between"
        >
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Ticker background
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={tickerBackground}
            onChange={(e) => onInputChange(e, setTickerBackground)}
          />
        </Form.Group>
        <Form.Group className="flex flex-row items-center justify-between">
          <Form.Label
            style={{
              marginBottom: 0,
            }}
          >
            Likes count
          </Form.Label>
          <Form.Control
            style={{
              maxWidth: "300px",
            }}
            value={likes}
            onChange={(e) => onInputChange(e, setLikes)}
          />
        </Form.Group>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
            backgroundColor: "rgba(255,255,255, 0.8)",
            position: "sticky",
            bottom: "0",
            right: "0",
          }}
        >
          {currentOffer && (
            <Button
              variant="danger"
              style={{
                marginRight: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm(`Точно? id: ${currentOffer.id}`)) {
                  deleteOffer(currentOffer.id);
                }
              }}
            >
              delete
            </Button>
          )}
          <Button
            variant="success"
            disabled={!isValid && isEdited}
            onClick={() => {
              const data = {
                _id: +id,
                title,
                isGreat: isDark,
                description,
                avatarImageUrl,
                headerImageUrl,
                link,
                ticker,
                trigger,
                tickerBackground,
                tags,
                tagsBackground,
                promo,
                viberLink,
                terms,
                locations,
                likesCount: likes,
              };
              if (currentOffer) {
                editOffer(currentOffer.id, data);
              } else {
                onAddNewOffer(data);
              }
            }}
          >
            {currentOffer ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Offer;
