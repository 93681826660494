import { Route, Routes as Switch } from "react-router-dom";
import Statistic from "./Statistic";
import Offers from "./Offers";
import Locations from "./Locations";
import Naming from "./Naming";

const Routes = () => {
  return (
    <Switch>
      <Route path="/statistic" element={<Statistic />} />
      <Route path="/naming" element={<Naming />} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/" exact element={<Offers />} />
    </Switch>
  );
};

export default Routes;
