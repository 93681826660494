import React from "react";
import NamingContainer from "../Containers/Naming";

const Naming = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <NamingContainer />
    </div>
  );
};

export default Naming;
