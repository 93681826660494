import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";

const linkResetStyles = {
  all: "unset",
};

const NavbarComponent = () => {
  const location = useLocation();

  const path = useMemo(() => location.pathname, [location.pathname]);

  return (
    <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand
          href="#"
          style={{
            color: "#80ed99",
            fontStyle: "bold",
          }}
        >
          Axioma Offers
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "50px",
            }}
          >
            <Link
              to="/"
              style={{
                ...linkResetStyles,
                color: path === "/" ? "#ffbe0b" : "white",
                cursor: "pointer",
              }}
            >
              Offers
            </Link>

            <Link
              to="/locations"
              style={{
                ...linkResetStyles,
                color: path === "/locations" ? "#ffbe0b" : "white",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            >
              Locations
            </Link>

            <Link
              to="/naming"
              style={{
                ...linkResetStyles,
                color: path === "/naming" ? "#ffbe0b" : "white",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            >
              Naming
            </Link>

            <Link
              to="/statistic"
              style={{
                ...linkResetStyles,
                color: path === "/statistic" ? "#ffbe0b" : "white",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            >
              Statistic
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
