import React, { useContext } from "react";
import DefaultsItem from "../Containers/DefaultsItem";
import { Modal } from "react-bootstrap";
import { OffersContext } from "../context/Offers";
import DefaultsContainer from "../Containers/Defaults";

const Locations = () => {
  const { defaultsModalOpen, closeDefaultsModal, currentDefaults } =
    useContext(OffersContext);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <DefaultsContainer />
      <Modal show={defaultsModalOpen} onHide={closeDefaultsModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {currentDefaults
              ? "Edit location ID: " + currentDefaults.id
              : "New location"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DefaultsItem />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Locations;
